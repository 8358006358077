<template>
  <b-container class="bg-white py-4">
    <b-row v-if="!permissionEditTopicUpdate">
      <b-col>
        <b-card>
          <h4>You do not have access permission for this page</h4>
        </b-card>
      </b-col>
    </b-row>
    <b-row v-if="permissionEditTopicUpdate">
      <b-col>
        <b-container>
          <b-row class="mb-4">
            <b-col>
              <router-link :to="{ name: 'Topic', params: { id: topic.id }}">back to topic page</router-link>
            </b-col>
          </b-row>
          <b-row class="mb-4">
            <b-col>
              <div class="mb-2"><strong>Title</strong></div>
              <div v-if="!edit.name" class="mb-2">
                {{topic.name}}
                <b-button v-if="permissionEditTopic" variant="outline-primary" size="sm" v-on:click="edit.name = true">Edit</b-button>
              </div>
                <b-form-input v-if="edit.name" id="titleInput"
                                v-model="topic.name"
                                placeholder="Regulation title"
                                required
                                class="mb-2">
                </b-form-input>
                <b-button v-if="validateTitle" class="mr-2" variant="primary" size="sm" v-on:click="saveProperty('name')">Save</b-button>
                <b-button v-if="edit.name" variant="outline-primary" size="sm" v-on:click="cancelSaveProperty('name')">Cancel</b-button>
            </b-col>
          </b-row>
          <b-row class="mb-4" v-if="permissionEditTopic">
            <b-col>
              <div class="mb-2"><strong>Headlines (markdown)</strong></div>
              <div v-if="!edit.comments" class="mb-2">
                <span v-if="!loadingTopic"><markdown :content="topic.comments" /></span>
                <b-button variant="outline-primary" size="sm" v-on:click="edit.comments = true">Edit</b-button>
              </div>
                <b-form-textarea v-if="edit.comments" id="detailsInput"
                                v-model="topic.comments"
                                placeholder="Regulation details"
                                required
                                class="mb-2">
                </b-form-textarea>
                <span v-if="!saving">
                <b-button v-if="validateComments" class="mr-2" variant="primary" size="sm" v-on:click="saveProperty('comments')">Save</b-button>
                <b-button v-if="edit.comments" variant="outline-primary" size="sm" v-on:click="cancelSaveProperty('comments')">Cancel</b-button>
                </span>
                <span v-if="saving"><i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i></span>
            </b-col>
          </b-row>
          <b-row class="mb-4">
            <b-col>
              <div class="mb-2"><strong>Updates (markdown)</strong></div>
              <div v-if="!edit.updates" class="mb-2">
                <span v-if="!loadingTopic"><markdown :content="topic.updates" /></span>
                <b-button variant="outline-primary" size="sm" v-on:click="edit.updates = true">Edit</b-button>
              </div>
                <b-form-textarea v-if="edit.updates" id="updatesInput"
                                v-model="topic.updates"
                                placeholder="regulatory updates"
                                class="mb-2">
                </b-form-textarea>
                <span v-if="!saving">
                <b-button v-if="validateUpdates" class="mr-2" variant="primary" size="sm" v-on:click="saveProperty('updates')">Save</b-button>
                <b-button v-if="edit.updates" variant="outline-primary" size="sm" v-on:click="cancelSaveProperty('updates')">Cancel</b-button>
                </span>
                <span v-if="saving"><i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i></span>
            </b-col>
          </b-row>
          <b-row class="mb-4" v-if="permissionEditTopic">
            <b-col>
              <div class="mb-2"><strong>Abstract (markdown)</strong></div>
              <div v-if="!edit.abstract" class="mb-2">
                <span v-if="!loadingTopic"><markdown :content="topic.abstract" /></span>
                <b-button variant="outline-primary" size="sm" v-on:click="edit.abstract = true">Edit</b-button>
              </div>
                <b-form-textarea v-if="edit.abstract" id="abstractInput"
                                v-model="topic.abstract"
                                placeholder="Regulation abstract"
                                rows = 20
                                required
                                class="mb-2">
                </b-form-textarea>
                <span v-if="!saving">
                <b-button v-if="validateAbstract" class="mr-2" variant="primary" size="sm" v-on:click="saveProperty('abstract')">Save</b-button>
                <b-button v-if="edit.abstract" variant="outline-primary" size="sm" v-on:click="cancelSaveProperty('abstract')">Cancel</b-button>
                </span>
                <span v-if="saving"><i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i></span>
            </b-col>
          </b-row>
          <b-row class="mb-4" v-if="permissionEditTopic">
            <b-col>
              <div class="mb-2"><strong>Development stage</strong></div>
              <div v-if="!edit.trackingStatus" class="mb-2">
                <span v-if="topic.trackingStatus" class="mr-2">{{topic.trackingStatus}}</span>
                <b-button variant="outline-primary" size="sm" v-on:click="edit.trackingStatus = true">Edit</b-button>
              </div>
                <b-form-select v-if="edit.trackingStatus" class="mb-2" v-model="trackingStatus" :options="trackingStatusOptions"/>
                <span v-if="!saving">
                <b-button v-if="validateTrackingStatus" class="mr-2" variant="primary" size="sm" v-on:click="saveProperty('trackingStatus')">Save</b-button>
                <b-button v-if="edit.trackingStatus" variant="outline-primary" size="sm" v-on:click="cancelSaveProperty('trackingStatus')">Cancel</b-button>
                </span>
                <span v-if="saving"><i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i></span>
            </b-col>
            <!--
            <b-col>
              <div class="mb-2"><strong>EU regulatory stage</strong></div>
              <div v-if="!edit.regulatoryStage" class="mb-2">
                <span v-if="topic.regulatoryStage" class="mr-2">{{topic.regulatoryStage}}</span>
                <b-button variant="outline-primary" size="sm" v-on:click="edit.regulatoryStage = true">Edit</b-button>
              </div>
                <b-form-select v-if="edit.regulatoryStage" class="mb-2" v-model="regulatoryStage" :options="regulatoryStageOptions" />
                <span v-if="!saving">
                <b-button v-if="validateRegulatoryStage" class="mr-2" variant="primary" size="sm" v-on:click="saveProperty('regulatoryStage')">Save</b-button>
                <b-button v-if="edit.regulatoryStage" variant="outline-primary" size="sm" v-on:click="cancelSaveProperty('regulatoryStage')">Cancel</b-button>
                </span>
                <span v-if="saving"><i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i></span>
            </b-col>
            -->
          </b-row>
          <b-row class="mb-4" v-if="permissionEditTopic">
            <!--
            <b-col>
              <div class="mb-2"><strong>Jurisdictions</strong></div>
              <div v-if="topic.jurisdictions" class="mb-2">
                <span v-for="item in topic.jurisdictions" :key="item">
                  {{item}}
                  <b-button variant="outline-primary" size="sm" v-on:click="deletePropertyList({ property: 'jurisdictions', value: item})">Remove</b-button>
                </span>
              </div>
                <span v-if="!saving">
                <b-form-select class="mb-2" v-model="jurisdictions" :options="options.jurisdictions"/>
                <b-button v-if="validateJurisdictions" class="mr-2" variant="primary" size="sm" v-on:click="savePropertyList('jurisdictions')">Save</b-button>
                </span>
                <span v-if="saving"><i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i></span>
            </b-col>
            -->
            <b-col>
              <div class="mb-2"><strong>Sectors</strong></div>
              <div v-if="topic.sectors" class="mb-2">
                <span v-for="item in topic.sectors" :key="item">
                  {{item}}
                  <b-button variant="outline-primary" size="sm" v-on:click="deletePropertyList({ property: 'sectors', value: item})">Remove</b-button>
                </span>
              </div>
                <span v-if="!saving">
                <b-form-select class="mb-2" v-model="sectors" :options="options.sectors"/>
                <b-button v-if="validateSectors" class="mr-2" variant="primary" size="sm" v-on:click="savePropertyList('sectors')">Save</b-button>
                </span>
                <span v-if="saving"><i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i></span>
            </b-col>
          </b-row>
          <b-row class="mb-4" v-if="permissionEditTopic">
            <b-col>
              <edge-select-list
                v-if="!loadingTopic"
                directionProp="from vertex"
                edgeLabelProp="inCategory"
                :listSourceProp="listSourcePropCategories"
                nameProp="Category"
                :vertexProp="topic"
                />
            </b-col>
          </b-row>
          <b-row class="mb-4" v-if="permissionEditTopic">
            <b-col>
              <property-boolean
                :id="topic.id"
                field="signals"
                :value="topic.signals"
                model="regtopic"
                nameDisplay="Show in Signals"
              >
              </property-boolean>
            </b-col>
          </b-row>
          <!--
          <b-row class="mb-4" v-if="permissionEditTopic">
              <b-col>
                <edge-select-list
                  v-if="!loadingTopic"
                  directionProp="from vertex"
                  edgeLabelProp="inRegulator"
                  :listSourceProp="{ partition: 'events', label: 'regulator' }"
                  nameProp="Regulators"
                  :vertexProp="topic"
                  />
              </b-col>
            </b-row>
          -->
          <b-row class="my-4" v-if="permissionDeleteTopic">
            <b-col>
              <h5>Deleting regulation</h5>
              <delete-vertex-soft :id="topic.id" label="regtopic" partition="events" @deleted="getTopics" />
            </b-col>
          </b-row>
        </b-container>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import _ from 'lodash'

import ac from '../libs/accesscontrol'
import countries from '../libs/countries'
import staticData from '../libs/static'

import DeleteVertexSoft from '@/components/DeleteVertexSoft.vue'
import EdgeSelectList from '@/components/cosmos/EdgeSelectList.vue'
import PropertyBoolean from '@/components/sql/PropertyBoolean.vue'
import Markdown from '@/components/Markdown.vue'

export default {
  name: 'TopicEdit',
  components: {
    DeleteVertexSoft,
    EdgeSelectList,
    Markdown,
    PropertyBoolean
  },
  computed: {
    user: {
      get () {
        return this.$store.state.user
      }
    },
    validateAbstract: function () {
      return this.edit.abstract
    },
    validateComments: function () {
      return this.edit.comments && this.topic.comments
    },
    validateJurisdictions: function () {
      return this.jurisdictions
    },
    validateRegulatoryStage: function () {
      if (this.regulatoryStage === 'undefined') {
        return false
      } else {
        return this.edit.regulatoryStage && this.regulatoryStage
      }
    },
    validateSectors: function () {
      return this.sectors
    },
    validateTitle: function () {
      return this.edit.name && this.topic.name
    },
    validateTrackingStatus: function () {
      return this.edit.trackingStatus && this.topic.trackingStatus
    },
    validateUpdates: function () {
      return this.edit.updates && this.topic.updates
    }
  },
  created: function () {
    this.$stat.log({ page: 'topic edit', action: 'open topic edit' })
    this.permissionEditTopic = ac.can(this.user.acgroups).createAny('topic').granted
    this.permissionDeleteTopic = ac.can(this.user.acgroups).deleteAny('topic').granted
    this.permissionEditTopicUpdate = ac.can(this.user.acgroups).createAny('topicUpdate').granted
    this.loadTopic()
  },
  data () {
    return {
      jurisdictions: null,
      edit: {
        abstract: false,
        comments: false,
        regulatoryStage: false,
        name: false,
        trackingStatus: false,
        updates: false
      },
      listSourcePropCategories: { partition: 'events', label: 'category' },
      loadingTopic: true,
      options: {
        jurisdictions: []
      },
      permissionEditTopic: false,
      saving: false,
      regulatoryStage: null,
      regulatoryStageOptions: staticData.regulatoryStage,
      sectors: null,
      topic: {
        abstract: '',
        name: '',
        comments: '',
        jurisdictions: [],
        regulatoryStage: 'waiting...',
        trackingStatus: 'waiting...',
        updates: ''
      },
      topicOld: {},
      trackingStatus: null,
      trackingStatusOptions: staticData.trackingStatus
    }
  },
  methods: {
    buildOptionsJurisdictions: function () {
      let optionsTemp = []
      optionsTemp[0] = { value: null, text: 'Select country' }
      for (let i = 0, len = countries.length; i < len; i++) {
        optionsTemp[i + 1] = {
          value: countries[i].country,
          text: countries[i].country
        }
      }
      if (this.topic.jurisdictions) {
        for (let i = 0, len = this.topic.jurisdictions.length; i < len; i++) {
          _.remove(optionsTemp, _.matchesProperty('value', this.topic.jurisdictions[i]))
        }
      } else {
        this.topic.jurisdictions = []
      }
      this.options.jurisdictions = optionsTemp
    },
    buildOptionsSectors: function () {
      let optionsTemp = []
      optionsTemp[0] = { value: null, text: 'Select sector' }
      for (let i = 0, len = staticData.sectors.length; i < len; i++) {
        optionsTemp[i + 1] = {
          value: staticData.sectors[i],
          text: staticData.sectors[i]
        }
      }
      if (this.topic.sectors) {
        for (let i = 0, len = this.topic.sectors.length; i < len; i++) {
          _.remove(optionsTemp, _.matchesProperty('value', this.topic.sectors[i]))
        }
      } else {
        this.topic.sectors = []
      }
      this.options.sectors = optionsTemp
    },
    cancelSaveProperty: function (property) {
      this.$logger.debug('cancel save comments')
      this.topic[property] = this.topicOld[property]
      this.edit[property] = false
    },
    deletePropertyList: async function (toDelete) {
      this.$logger.debug('deletePropertyList started')
      this.saving = true
      try {
        // let response = await this.$http(config)
        let apiName = 'cosmos'
        let path = `/propertylist`
        let params = {
          body: {
            property: {
              partition: 'events',
              id: this.topic.id,
              propertyName: toDelete.property,
              propertyValue: toDelete.value,
              table: 'regtopics'
            }
          }
        }
        let response = await this.$Amplify.API.del(apiName, path, params)
        this.$logger.debug('deleted OK:', response)
        let temp = this.topic[toDelete.property]
        _.pull(temp, toDelete.value)
        temp.sort()
        this.topic[toDelete.property] = temp
        this.buildOptionsJurisdictions()
      } catch (e) {
        this.$logger.warn('delete error: ' + e)
      }
      this.saving = false
    },
    deleteTopic: async function () {
      this.$logger.debug('deleteTopic started')
      this.saving = true
      try {
        // let response = await this.$http(config)
        let apiName = 'cosmos'
        let path = `/topic`
        let params = {
          body: {
            id: this.topic.id
          }
        }
        let response = await this.$Amplify.API.del(apiName, path, params)
        this.$logger.debug('deleted OK:', response)
        this.$router.push({ name: 'Topics' })
      } catch (e) {
        this.$logger.warn('delete error: ' + e)
      }
      this.saving = false
    },
    getTopics: function () {
      this.$router.push({ name: 'Topics' })
    },
    loadTopic: async function () {
      this.$logger.debug('loadTopics started')
      this.loadingTopic = true
      try {
        let apiName = 'cosmos'
        let path = `/topic/${this.$route.params.id}`
        let response = await this.$Amplify.API.get(apiName, path)
        this.topic = response
        this.topicOld.name = this.topic.name
        this.topicOld.comments = this.topic.comments
        this.topicOld.abstract = this.topic.abstract
        this.topicOld.updates = this.topic.updates
        if (!this.topic.regulatoryStage) {
          this.topic.regulatoryStage = 'undefined'
          this.regulatoryStage = 'undefined'
          this.topicOld.regulatoryStage = 'undefined'
        } else {
          this.topicOld.regulatoryStage = this.topic.regulatoryStage
          this.regulatoryStage = this.topic.regulatoryStage
        }
        if (!this.topic.trackingStatus) {
          this.topic.trackingStatus = 'undefined'
          this.topicOld.trackingStatus = 'undefined'
          this.trackingStatus = 'undefined'
        } else {
          this.topicOld.trackingStatus = this.topic.trackingStatus
          this.trackingStatus = this.topic.trackingStatus
        }
        this.buildOptionsJurisdictions()
        this.buildOptionsSectors()
        this.loadingTopic = false
      } catch (e) {
        this.$logger.warn('/topic/:id error', e)
      }
    },
    saveProperty: async function (property) {
      this.$logger.debug(`saving ${property} start`)
      this.topic.regulatoryStage = this.regulatoryStage
      this.topic.trackingStatus = this.trackingStatus
      this.saving = true
      try {
        // let response = await this.$http(config)
        let apiName = 'cosmos'
        let path = `/property`
        let params = {
          body: {
            property: {
              partition: 'events',
              id: this.topic.id,
              propertyName: property,
              propertyValue: this.topic[property],
              table: 'regtopics'
            }
          }
        }
        let response = await this.$Amplify.API.put(apiName, path, params)
        this.$logger.debug('saved OK:', response)
        this.edit[property] = false
        this.topicOld[property] = this.topic[property]
      } catch (e) {
        this.$logger.warn('event saving error: ' + e)
      }
      this.saving = false
    },
    savePropertyList: async function (property) {
      this.$logger.debug(`saving ${property} start`)
      this.saving = true
      try {
        // let response = await this.$http(config)
        let apiName = 'cosmos'
        let path = `/propertylist`
        let params = {
          body: {
            property: {
              partition: 'events',
              id: this.topic.id,
              propertyName: property,
              propertyValue: this[property],
              table: 'regtopics'
            }
          }
        }
        let response = await this.$Amplify.API.put(apiName, path, params)
        this.$logger.debug('saved OK:', response)
        this.edit[property] = false
        this.topic[property].push(this[property])
        this.topic[property].sort()
        let options = this.options[property]
        _.remove(options, _.matchesProperty('value', this[property]))
        this.options[property] = [{ value: null, text: null }]
        this.options[property] = options
        this[property] = null
      } catch (e) {
        this.$logger.warn('event saving error: ' + e)
      }
      this.saving = false
    }
  }
}
</script>

<style>
</style>
